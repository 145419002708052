.parents {
  width: 100%;
  height: auto;
  margin: auto;
}
.Home {
  /* max-width: 1280px; */
  height: 600px;
  margin: auto;
  /* background-image: url("https://images-pw.pixieset.com/sample_photos/sample-170_XL.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; */
  /* border-radius: 15px; */
  /* background-color: red; */
  overflow: hidden;
  position: relative;
  z-index: -10;
  /* margin-top: 2rem; */
}
.child2 {
  /* width: 1200px; */
  height: 500px;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: space-evenly;
  align-items: center;
}

.child2-left {
  width: 45%;
  height: 65%;
}
.heading {
  margin-bottom: 20px;
  /* font-family: "Playfair Display", serif; */
  font-optical-sizing: auto;
  font-weight: 600;
  text-decoration: underline;
  font-style: normal;
  padding: 0px 40px;
}

@media screen and (max-width: 768px) {
  .heading {
    text-align: center;
    padding: 0px 30px;
  }
}
.paragraph {
  /* font-family: "Saira Semi Condensed", sans-serif; */
  padding: 0px 40px;
}
@media screen and (max-width: 768px) {
  .child2 {
    flex-direction: column;
  }
}

.carousel {
  position: relative;
  height: 100%;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 100%;
}
.carousel-inner img {
  height: 100%;
}
.child2-right {
  /* margin-top: 8rem;
  width: 70%;
  height: 94%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 15px; */
  background-image: url("https://images-pw.pixieset.com/sample_photos/sample-170_XL.jpg");
}
.child2-right:hover {
  background-image: url("https://images-pw.pixieset.com/sample_photos/sample-168_XL.jpg");
  transition: 3s ease-out;
}
/* 
@media screen and (max-width: 768px) {
  .child2-right {
    margin-top: 0;
  }
} */

.grid {
  /* width: 1200px; */
  height: 330px;
  margin: 100px auto 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.grid1 {
  width: 33%;
  height: 99%;
}
.grid1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.quote {
  width: 700px;
  height: 150px;
  margin: auto;
  margin-top: 50px;
}

@media screen and (max-width: 768px) {
  .quote {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }

  .paragraph {
    padding: 0px 30px;
  }
}
.quote h2 {
  margin-top: 13px;
  /* font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; */
  font-size: 20px;
  letter-spacing: 2px;
}
.quote p {
  /* font-family: cursive; */
  margin-top: -2px;
  color: rgb(239, 15, 15);
  font-weight: 600;
}
