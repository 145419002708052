@import url("https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100..900;1,100..900&family=Cinzel:wght@400..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow-x: hidden;
  font-family: "Hanken Grotesk", "Cinzel" sans-serif;
}

.file-choose input[type="file"] {
  color: rgba(0, 0, 0, 0);
}

/* body {
} */
/* .slick-arrow .slick-prev {
  color: orange;
  background-color: orange;
}

.slick-prev:before {
  content: '←';
} */
/* .slick-prev:before,
.slick-next:before {
  color: orange;
  background-color: red;
} */
/* button.slick-arrow.slick-prev::before {
  color: red;
  font-size: 10px;
  z-index: 1;
}
button.slick-arrow.slick-next::before {
  color: red;
} */
