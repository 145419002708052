* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.Aboutus {
  width: 100%;
  height: auto;
  padding: 10px;
  margin: auto;
}
.submain {
  /* width: 1200px; */
  height: 540px;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.submain-left {
  width: 35%;
  height: 97%;
}
.submain-right {
  width: 41%;
  height: 97%;
  padding: 25px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  border-radius: 15px;
}
.submain-right h1 {
  margin: 10px 0px;
}
.slider {
  width: 1280px;
  /* height: 500px; */
  margin: 100px auto 70px;
}
.contact-me {
  width: 200px;
  height: 75px;
  background-color: black;
  color: white;
  font-weight: 700;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.sliderimg {
  object-fit: cover;
  /* height: 500px; */
  width: 100%;
}
.contact-me:hover {
  border: 2px solid black;
  background-color: white;
  color: black;
  transition: all 0.1s linear;
}
