.footer {
  width: 100%;
  height: 165px;
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 55px;

  /* margin: auto; */
}
.sections {
  /* width: 100%; */
  height: 100%;
}

.sections img {
  width: 250px;
  height: 200px;
  object-fit: cover;

  /* border: 2px solid black; */
  border-radius: 8%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  padding: 2px;
}

@media only screen and (max-width: 768px) {
  .sections {
    width: 100%;
    height: 100%;
  }
  .footer {
    display: flex;
    flex-direction: column;
  }
}
.bigie {
  width: 100%;
  height: 400px;
  background-color: cadetblue;
  margin-top: 20px;
  text-align: center;
  overflow: hidden;
  padding: 100px;
}
.bigie-center {
  width: 400px;
  height: 360px;
  position: relative;

  margin: auto;
}
.bigie-center i {
  margin: 13px;
  font-size: 25px;
}
.navbar2 ul {
  position: absolute;
  bottom: 190px;
  left: 1%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.navbar2 ul li {
  padding: 0px 10px;
}
.bigie-center p {
  position: absolute;
  bottom: 100px;
  left: 10%;
}
